.inline {
    li {
        display: inline;
    }
}

.list-basic {
    margin: $baseListSpacing $baseListSpacing * 2;
    padding: 0;
    position: relative;

    li {
        position: relative;

        &:before {
            color: $gray;
            content: "\25A0";
            left: -$baseListSpacing * 2;
            position: absolute;
            line-height: $baseLineHeight * .88;
        }

        > ul {
            margin-left: $baseListSpacing * 2;

            > li:before {
                content: "\25CF";
            }

            > li > ul > li:before {
                color: $darkGray;
                content: "\25CB";
            }
        }
        + li {
            margin-top: $baseListSpacing;
        }
    }

    &.disclaimer {
        li {
            &:before {
                line-height: 1;
            }
        }
    }
    &.-check{
        li{
            &:before{
                font-family: 'American Icons';
                content: "\10e049";
                @include font-size($h3-size);
            }
        }
    }
    &.-cross{
        li{
            &:before{
                font-family: 'American Icons';
                content: "\10e04a";
                @include font-size($h3-size);
            }
        }
    }
}

.list-standard {
    padding: 9px 0;

    & > li {
        margin-bottom: 9px;
    }
}

ol.list-standard {
    list-style: decimal;
}
ol.list-decimal {
    list-style-type: decimal;
    list-style-position: inside;

    li {

        > ol {
            list-style-type: lower-roman;
            list-style-position: inside;
            margin-left: $baseListSpacing * 3;
        }
    }
}

ol.list-alpha-upper{
    list-style: upper-alpha;
    list-style-position: inside;

    li {

        > ol {
            list-style-type: lower-alpha;
            list-style-position: inside;
            margin-left: $baseListSpacing * 3;
        }
    }
}

.list-inline {
    > li {
        display: inline-block;

        & + li {
            margin-left: 18px;
        }
    }
}

.menu-list {
    float: left;
    margin: 14px 0 22px;
    width: 100%;

    > li {
        &:first-child {
            padding-top: 0;
        }
    }

    a {
        display: block;
        color: $gray04;
        padding: 5px 0 4px 4px;
    }

    a:hover {
        color: $lightBlue;
    }
}

.aa-ul-list-basic {
    @extend .list-basic;
}

[class^="list-icon"] > li {
    display: block;
}

[class^="list-icon"] > li:before {
    font-family: "American Icons";
    font-size: 18px;
    margin-right: .5rem;
    vertical-align: middle;
}

.list-icon-check > li:before {
    content: "\10e049";
    color: $green;
}

[class$="green"]:before {
    color: $green;
}

[class$="red"]:before {
    color: $darkRed;
}

.list-icon [class^="icon-check"]:before {
    content: "\10e049";
}

.list-icon [class^="icon-cancel"]:before {
    content: "\10e04a";
}


ul.flexible-columns, ul.list-columns {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
ol.flexible-columns, ol.list-columns {
    list-style-type: decimal;
    list-style-position: inside;
}
.flexible-columns, .list-columns {
    li:first-child {
        margin-top: 0px;
    }
}
.flexible-columns {
    -moz-column-width: 11.5em;
    -webkit-column-width: 11.5em;
    column-width: 11.5em;
}

$number: two 2,three 3,four 4,five 5;
@each $count in $number {
    .list-columns.#{nth($count,1)}
     {
        -moz-column-count: nth($count,2);
        -webkit-column-count: nth($count,2);
        column-count: nth($count,2);
    }
}

.lt-ie9, .lt-ie10 {
    .flexible-columns, .list-columns {
        width: 100%;
        @include clearfix;
        li {
            float: left;
        }
    }
}

ul.margin-small li{
    margin: 0 0 10px 0;
}

.lt-ie9, .lt-ie10 {
    .list-columns {
        &.two li {
            width: 48%;
        }
        &.three li {
            width: 30%;
        }
        &.four li {
            width: 24%;
        }
        &.five li {
            width: 19%;
        }
    }
    .flexible-columns li {
        width: 24%;
    }
}
ul.list-inline-separator {
    clear: both;
    font-family: $sansFontFamilyRegular;
    margin-bottom: $baseLineHeight;
    padding-bottom: $baseLineHeight;
    li {
        float: left;
        margin-left: $baseLineHeight;
        &:before {
            color: $lightGray;
            content: "\25A0";
            display: inline-block;
            @include font-size(8);
            margin-left: -$baseLineHeight;
            text-align: center;
            vertical-align: top;
            width: $baseLineHeight;
        }
        &:first-child {
            margin-left: 0;
            &:before {
                content: "";
            }
        }
    }
}

.link-list{
    li{
      display:inline;
    &:before{
      content: "|";
      padding:10px;
      color:$gray;
  }
    &:first-child:before{
      content: "";
      padding:0;
  }
  }
}
