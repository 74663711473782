.aa-thread {
    background-color: white;
    bottom: 0;
    content: "";
    left: 0;
    height: 6px;
    width: 100%;
    position: absolute;
}

.aa-tier-level-EXP .aa-thread {
    background-image: url(/content/images/chrome/rebrand/aa-thread-exp.png);
    background-position: bottom left;
    background-repeat: repeat-x;
    @include background(linear-gradient(left, $tierEXPDark, $tierEXPMid 15%, $tierEXPLight 25%, $tierEXPDark 45%, $tierEXPMid 65%, $tierEXPDark 79%, $tierEXPMid 90%, $tierEXPDark));
}

.aa-tier-level-PLT .aa-thread {
    background-image: url(/content/images/chrome/rebrand/aa-thread-plt.png);
    background-position: bottom left;
    background-repeat: repeat-x;
    @include background(linear-gradient(left, $tierPLTLight, $tierPLTMid 15%, $tierPLTDark 25%, $tierPLTLight 46%, $tierPLTMid 52%, $tierPLTDark 63%, $tierPLTMid 79%, $tierPLTDark 80%, $tierPLTMid 97%, $tierPLTLight));
}

.aa-tier-level-GLD .aa-thread {
    background-image: url(/content/images/chrome/rebrand/aa-thread-gld.png);
    background-position: bottom left;
    background-repeat: repeat-x;
    @include background(linear-gradient(left, $tierGLDDark, $tierGLDLight 7%, $tierGLDLight 29%, $tierGLDMid 62%, $tierGLDLight 88%, $tierGLDDark 96%, $tierGLDMid));
}

.aa-tier-level-REG .aa-thread {
    background-image: url(/content/images/chrome/rebrand/aa-thread-reg.png);
    background-position: bottom left;
    background-repeat: repeat-x;
    @include background(linear-gradient(left, $tierREGLight, $tierREGMid 15%, $tierREGDark 25%, $tierREGLight 46%, $tierREGMid 52%, $tierREGDark 63%, $tierREGMid 79%, $tierREGDark 80%, $tierREGMid 97%, $tierREGLight));
}
