$positions: ('top','bottom','middle','both');
$sizes:     ('xsmall','small','large','xlarge');

//Margins
@each $position in $positions {
    .margin-#{$position} {
        @include margin($position);
        @each $size in $sizes {
            &.-#{$size} {
               @include margin($position,$size);
            }
        }
    }
}

//Horizontal Rules
.section {
    + .section {
        @include hr-image('top','xlarge');
    }
    .section + .section {
        @include hr-line('top','large');
        background: none;
    }
    p + & {
        @include margin('top');
    }
}
@each $position in $positions {
    .hr-line-#{$position} {
        @include hr-line($position);
    }
}
@each $position in $positions {
    .hr-dashed-#{$position} {
        @include hr-dashed($position);
    }
}
@each $position in $positions {
    .hr-image-#{$position} {
        @include hr-image($position);
    }
}
@each $position in $positions {
    [class*="hr-"][class*="-#{$position}"] {
        @each $size in $sizes {
            &.-#{$size} {
                @include margin($position,$size);
                @include padding($position,$size);
            }
        }
    }
}
.hr-middle {
    overflow: hidden;
    text-align: center;
    &:before, &:after {
        border-top: 1px dashed $mediumGray;
        content: "";
        display: inline-block;
        position: relative;
        vertical-align: middle;
        width: 100%;
    }
    &:before {
        right: 1.2em;
        margin-left: -100%;
    }
    &:after {
        left: 1.2em;
        margin-right: -100%;
    }
}
