$messages: (
    info: $green,
    error: $darkRed,
    warning: $orange,
    general: $darkBlue,
    success: $green
);

[class|="message"] {
    clear: both;
    margin: 0;
    padding: $baseLineHeight/2 0 $baseLineHeight/2 34px;
    position: relative;

    .header {
        @extend .gamma;
        margin-bottom: $baseLineHeight/2;
    }

    &:before {
        font-family: "American Icons";
        @include font-size(24);
        position: absolute;
        left: 0;
        top: $baseLineHeight/2+3;
    }

    &:not([style*="display:none"]):not([style*="display: none"]):not(.is-hidden) ~ [class|="message"]{
        border-top: 1px dashed $lightGray;
        margin-top: $baseLineHeight/2;
        padding-top: $baseLineHeight;
        &:before {
            top: $baseLineHeight+3;
        }
    }

    &:last-of-type {
        margin-bottom: $baseLineHeight;
    }
}

[class|="message-inline"] {
    background: none;
    margin: 0;
    padding: 0;

    + [class|="message-inline"]{
            padding-top: $baseLineHeight/2 !important;
        }

    &:before {
        font-family: "American Icons";
        font-size: inherit;
        margin-right: .5rem;
        position: static;
    }

    a, a:hover {
        text-decoration: underline;
    }

    .lt-ie9 & {
        border-top: none;
        border-bottom: none;

        &:before {
            margin-right: 5px;
        }
    }
}

@each $type, $color in $messages {
    .message-#{$type} {
        .header {
            color: $color;
        }

        &:before {
            color: $color;
            @extend .icon-#{$type}:before;
        }

        @if $type == error {
            label.is-#{$type}:before {
                content: '';
            }
        }
    }

    .message-inline-#{$type} {
        @extend .icon-#{$type};
        color: $color;
    }

    .is-#{$type} {
        &.-message {
            @include font-size($sm-size);
            line-height: 15px;
            margin-bottom: 5px;
        }
    }
}

span.-message {
    display: inline-block;
    &.is-hidden, &.ng-hide {
        display: none;
    }
}
