* {
    @include box-sizing(border-box);
    *behavior: url(/content/common/css/boxsizing.htc);
}

html {
    font-family: $baseFontFamily;
    font-size: $baseFontSize / 16 + em;
    line-height: $baseLineHeight;
}

body {
    background-color: white;
    color: $bodyColor;
    line-height: inherit;
    position: relative;
}

a, button#plan-travel-expander, button#travel-information-expander, button#aadvantage-expander {
    color: $linkColor;
    text-decoration: none;
    &:hover {
        color: $linkHoverColor;
        text-decoration: underline;
    }
    &:focus {
        color: $linkHoverColor;
        outline: 2px solid $linkHoverColor;
        text-decoration: underline;
    }
    &.text-underline {
        &:hover,&:focus {
            text-decoration: inherit !important;
        }
    }
}

h1, .alpha {
    @include head1;

    [class^="icon-"]:before {
        @include font-size(22);
        vertical-align: middle;
    }
}

h2, .beta {
    @include head2;

    [class^="icon-"]:before {
        @include font-size(16);
        vertical-align: middle;
    }
}

h3, .gamma {
    @include head3;

    [class^="icon-"]:before {
        @include font-size(16);
    }
}

h4, .delta {
    @include head4;

    [class^="icon-"]:before {
        @include font-size(16);
    }
}

h5, .epsilon {
    @include head5;

    [class^="icon-"]:before {
        @include font-size($baseFontSize);
        vertical-align: baseline;
    }
}

h6, .zeta {
    @include head6;
    white-space: nowrap;
}

p {
    margin-bottom: $baseLineHeight/2;
}

.disclaimer {
    color: $mediumGray;
    @include font-small;
    [class^="icon-"]:before {
        @include font-size(11);
        vertical-align: baseline;
    }
}

h1 {
    color: $brandBlue;
    margin: $baseLineHeight*1.5 0 $baseLineHeight;
}

h2 {
    color: $darkBlue;
    margin: 0 0 $baseLineHeight;
}

h3 {
    color: $mediumGray;
    margin: 0 0 $baseLineHeight;
}

h4, h6{
	color: $mediumGray;
	margin: 0 0 $baseLineHeight/2;
}

h5{
    color: $darkGray;
    margin: 0 0 $baseLineHeight/2;
}

img {
    max-width: 100%;
}

header {
    position: relative;
}

hr {
    border: 1px solid $gray02;
    border-width: 1px 0 0 0;
    height: 1px;
    margin: $baseLineHeight*1.5 0;
}

small {
    @include font-small;
    font-weight: normal;

    [class^="icon-"]:before {
        font-size: inherit;
    }
}

b,strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

h1,h2,h3,h4,h5,h6,p,div,table,span,footer,header,nav,section {
    @include focus-accessible;
}

#main-navigation, #aa-content-frame, #aa-footer {
    &:focus {
        outline: none;
    }
}

sup {
    @include superscript;
    font-size: 0.5em;
}

.info-note {
    @include info-note;
}
