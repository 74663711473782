footer {
    margin-top: 60px;
    padding-bottom: 72px;
    background-color: $gray02;
    @include font-size(12);
    line-height: 18px;

	h2 {
        color: $darkGray;
        font-family: $sansFontFamilyRegular;
        font-size: 18px;
        font-size: 1.286rem;
        line-height: 18px;
        font-weight: normal;
        padding: 0 0 13px 0;
        margin: 0 0 14px 0;
        display: block;
        background-image: url(/content/images/chrome/rebrand/shadow12-down.png);
        background-position: 50% top;
        background-repeat: no-repeat;
        background-size: 100% 4px;
        background-position: 50% bottom;
        background-size: 100% 4px;
        width: 100%;
    }

    a {
        color: $altLinkColor;
        display: inline-block;
        &:focus {
            color: $darkBlue;
            text-decoration: underline;
        }

        &:hover {
            color: $darkBlue;
            text-decoration: underline;
        }
    }

    .container {
        @include shadow-down;
        @include selfclear;
        padding-top: 36px;

        .row {

        	ul {

                li {
                    margin-bottom: 9px;
                }

                #aa-ad-chiclet-lnkFooter2 {
                    text-align: center;
                }
            }

            dl {
                margin-top: 36px;

                dt {
                    @include head4;
                    font-family: $sansFontFamilyRegular;
                    font-weight: normal;
                    padding: 0px 0px 13px 0px;
                    margin: 0px 0px 14px 0px;
                    display: block;
                    @include shadow-down-bottom;
                    background-size: 100% 4px;
                    width: 100%;
                }

                dd {
                    margin-bottom: 9px;
                }
            }
        }

        hr {
            width: 100%;
            height: 4px;
            border: none;
            clear: both;
            margin: 18px 0px 20px 0px;
            @include clearfix;
            @include shadow-down;
        }

        .social {
            float: right;
            @include selfclear;

            a {
                margin-right: 4px;
                float: left;
                display: block;

                &.feedback {
                    white-space: nowrap;
                    margin-top: 4px;
                    margin-left: 30px;
                    font-size: 10px;
                    font-family: Helvetica, Arial, sans-serif;
                    display: block;
                    text-transform: uppercase;

                    img {
                    	margin-left: 10px;
                        border: 0;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
