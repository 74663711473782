#aaJsWarning{
	display:none;
}
.no-js {
	& #aaJsWarning{
		display:block;
	}
}
.close {
    color: $bodyColor;
    cursor: pointer;
    float: right;
    font-size: 18px;
    line-height: 1;
    opacity: 0.6;

    &:hover {
        color: $bodyColor;
        opacity: 1;
        text-decoration: none;
    }
}

.alert {
    color: white;
    background-color: $gray03;
    @include box-shadow(0 0 18px $gray04 inset);
    font-weight: normal;
    padding: 9px 35px 9px 18px;

    .icon {
        float: left;
    }

    .close {
        color: white;
        position: relative;
    }
     a {
        color: white;
        text-decoration: underline;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

.alert-system {
    @include theme-superUltraLightGray;
    font-weight: normal;
    padding: 9px 35px 9px 18px;

      + .alert-system  {
      border-top:1px dotted #d0dae0;
}
}

.alert-error {
    background-color: $red;
    color: $white;
}

.alert-success {
    background-color: $green;
    color: $white;
}

.alert-warning {
    background-color: $lightOrange;
    color: $white;
}

.alert-global {
    margin: 0 -18px;

    [class|="message"]{
    	margin-bottom: 0px;
    	padding-top: 0px;
    	padding-bottom: 0px;

    	&:before{
    		top: 5px;
    	}
    }
    [class|="message-inline"]:before {
    	margin-left: -1.5em;
    }
    p {
        margin-left: 30px;
    }

    .icon {
        float: left;
    }
    body > & {
    	margin: 0;
    }
}

.alert-block {
    padding-top: 20px;
    padding-bottom: 20px;
}

.alert-small {
    font-weight: bold;
    line-height: 1.5;
    padding: 9px;
}

.alert-tab {
    bottom: 0;
    line-height: 27px;
    padding: 0 18px 0 9px;
    position: absolute;
    right: 18px;
    z-index: 0;

    > [class^="icon-"] {
        margin-right: 9px;
    }

    a:first-child {
        margin-left: 9px;
    }

    a:link {
        display: inline-block;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}

.alert-box {
    margin: 88px auto 27px;
    position: relative;
    text-align: center;
    width: 580px;
    z-index: 100;
}

.alert-box-content {
    overflow: hidden;
    padding: 27px 0;
}

.alert-box-content h1 {
    font-size: 36px;
}

.alert-box-content + p {
    margin: 9px 0;
}

/*  special alert instance - using Modernizr's .js/.no-js, hide the JavaScript Alert
*   if JavaScript is enabled.  Shown by default and JS required to hide it.
*/
.js #aaJsWarning {
    display: none;
}

#cookieBannerContainer {
    position: fixed;
    bottom: 0;
    z-index: 98;
    width: 100%;
}
