.container {
    margin: 0 auto;
	width: $fixedContainerWidth;
}

.row-noBreak{
    position: relative;
    @include clearfix;
}

.row,
.row-noBreak,
[class*="row-"] {
    margin-left: $fluidGridGutterWidth * -1;
    position: relative;
    @include clearfix;

    &.tab-bottom {
        padding-bottom: 29px;
    }
}

.row-space {
    @include margin('both');
}

.row-margin-top {
    margin-top: $fluidGridGutterWidth;
}

[class*="span"] {
    float: left;
    margin-left: $fluidGridGutterWidth;
}

.pushRight + [class*="span"] {
    margin-left: 0;
}

@for $i from 1 through $gridColumns {
    .span#{$i} {
        width: spanWidth($i);

        &.pushRight,
        &.pushLeft {
            width: spanWidth($i) + $fluidGridGutterWidth;
        }

        &.pushLeft {
            margin-left: 0;
        }
    }
}

@for $i from 1 through $gridColumns {
    .forceWidth#{$i} {
        width: forceWidth($i);
    }
}

@for $i from 1 through $gridColumns {
    .width#{$i} {
        width: spanWidth($i);
    }
}

@for $i from 1 through $gridColumns {
    .offset#{$i} {
        margin-left: offsetWidth($i);
    }
}

@for $i from 1 through $gridColumns {
    .offset-right#{$i} {
        margin-right: offsetWidth($i);
    }
}

.span-centered {
    float: none;
    margin: 0 auto;
}
