#aa-language-select {
    margin-right: 6px;
    position: relative;
    z-index: 600;
}

.aa-language-select-wrapper {
    overflow: hidden;
    position: relative;
}

#aa-language-select-tab {
    margin: 0 4px 0;
    padding: 0;
    position: relative;

    a {
        padding-bottom: 9px;
    }
}

#aa-language-select-tab.active {
    background-color: white;
    padding-bottom: 6px;
    position: relative;
    z-index: 110;
    @include box-shadow(0 0 6px 1px rgba(0, 0, 0, 0.15));
}

.aa-meta-nav-dropDown {
    background: white;
    @include box-shadow(0 0 6px 1px rgba(0, 0, 0, 0.15));
    left: 4px;
    padding: 18px 9px 9px;
    position: absolute;
    top: 42px;
    width: 236px;
    z-index: 100;

    .close {
        display: block;
        padding: 0;
        position: absolute;
        right: 9px;
        text-align: center;
        top: 9px;
    }
}
