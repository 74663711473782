[data-behavior~="dropdown"] {
    position: relative;
}

[data-behavior~="dropdown-wrapper"] {
    padding: 0 6px;
    overflow: hidden;
    z-index: 110;
    position: relative;
    white-space: nowrap;
}

[data-behavior~="dropdown-trigger"] {
    background-image: none;
    display: block;
    padding: 0 9px 2px 9px;
    position: relative;

    &.is-active {
        background-color: white;
        box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);
        color: $lightBlue;
    }

    .menu-item-utility & {
        color: $gray03;

        &:hover {
            color: $linkHoverColor;
        }
    }

    &.language-drop-indicator{
	    margin-left: 3px;
	    vertical-align: middle;
	}

    .dropdown-indicator {
        margin-left: 3px;
        vertical-align: middle;
    }
}

[data-behavior~="dropdown-panel"] {
    display: none;
    min-width: 252px;
    background-color: white;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);
    left: 6px;
    padding: 12px;
    position: absolute;
    z-index: 100;

    .dropdown-right & {
        left: auto;
        right: 6px;
    }
}

[data-behavior~="dropdown-close"] {
    display: block;
    text-align: right;
}
