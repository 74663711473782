@import "compass-shim";

@include font-face("American Icons", font-files(
    "#{$americanIconFontPath}american-icons-v4-8.woff", woff,
    "#{$americanIconFontPath}american-icons-v4-8.ttf", truetype,
    "#{$americanIconFontPath}american-icons-v4-8.svg#american-icons", svg),
    "#{$americanIconFontPath}american-icons-v4-8.eot", normal, normal);

[class*="icon-arrow-"]{
    margin-left: inherit;
    &:before,
    &:after {
        display: inherit;
    }
}

[class*="icon-"] {
    display: inline-block;

    &:before {
        @include font-size(13);
        font-family: 'American Icons';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        speak: none;
        -webkit-font-smoothing: antialiased;
    }
}

.alert-tab > [class*="icon-"] {
    &:before {
        vertical-align: middle;
    }
}

.icon-18:before {
    @include font-size(18);
}

.icon-small:before {
    @include font-size($icon-small);
}

.icon-default:before {
    @include font-size($icon-default);
}

.icon-medium:before {
    @include font-size($icon-medium);
}

.icon-large:before {
    @include font-size($icon-large);
}

.icon-xlarge:before {
    @include font-size($icon-xlarge);
}

.icon-xxlarge:before {
    @include font-size($icon-xxlarge);
}

.icon-xxxlarge:before {
    @include font-size($icon-xxxlarge);
}


.icon-align-text-bottom:before {
    vertical-align: text-bottom;
}

.icon-align-bottom:before {
    vertical-align: bottom;
}

.icon-people:before {
    content: "\10e000";
}

.icon-attendant:before {
    content: "\10e001";
}

.icon-stairs:before {
    content: "\10e002";
}

.icon-escalator-b:before {
    content: "\10e003";
}

.icon-escalator:before {
    content: "\10e004";
}

.icon-hotel:before {
    content: "\10e005";
}

.icon-handicap:before,
.icon-specialAssistance:before {
    content: "\10e006";
}

.icon-seats:before {
    content: "\10e007";
}

.icon-carseat:before {
    content: "\10e008";
}

.icon-stroller:before {
    content: "\10e009";
}

.icon-checkin:before {
    content: "\10e00a";
}

.icon-book:before {
    content: "\10e00b";
}

.icon-account:before {
    content: "\10e00c";
}

.icon-join:before {
    content: "\10e00d";
}

.icon-suitcase:before {
    content: "\10e00e";
}

.icon-twobags:before {
    content: "\10e00f";
}

.icon-threebags:before {
    content: "\10e010";
}

.icon-checkbags:before {
    content: "\10e011";
}

.icon-Trolley:before {
    content: "\10e012";
}

.icon-baginfo:before {
    content: "\10e013";
}
.icon-star:before{
	content: "\2606";
}
.icon-flight:before {
    content: "\10e014";
}

.icon-car:before {
    content: "\10e015";
}

.icon-taxi:before {
    content: "\10e016";
}

.icon-vacation:before {
    content: "\10e017";
}

.icon-activity:before {
    content: "\10e018";
}

.icon-entertainment:before {
    content: "\10e019";
}

.icon-cruise:before {
    content: "\10e01a";
}

.icon-food:before {
    content: "\10e01b";
}

.icon-foodpurchase:before {
    content: "\10e01c";
}

.icon-snack:before {
    content: "\10e01d";
}

.icon-drink:before {
    content: "\10e01e";
}

.icon-coffee:before {
    content: "\10e01f";
}

.icon-nosmoking:before {
    content: "\10e020";
}

.icon-payment:before {
    content: "\10e021";
}

.icon-cash:before {
    content: "\10e022";
}

.icon-scanswipe:before {
    content: "\10e023";
}

.icon-promotion:before {
    content: "\10e024";
}

.icon-boardingpass:before {
    content: "\10e025";
}

.icon-onedaypass:before {
    content: "\10e026";
}

.icon-mobile:before {
    content: "\10e027";
}

.icon-nomobile:before {
    content: "\10e028";
}

.icon-game:before {
    content: "\10e029";
}

.icon-contact:before {
    content: "\10e02a";
}

.icon-print:before {
    content: "\10e02b";
}

.icon-website:before {
    content: "\10e02c";
}

.icon-email:before {
    content: "\10e02d";
}

.icon-clock:before {
    content: "\10e02e";
}

.icon-wifi:before {
    content: "\10e02f";
}

.icon-location:before {
    content: "\10e030";
}

.icon-maps:before {
    content: "\10e031";
}

.icon-gps:before {
    content: "\10e032";
}

.icon-home:before {
    content: "\10e033";
}

.icon-notifications:before {
    content: "\10e034";
}

.icon-feedback:before {
    content: "\10e035";
}

.icon-settings:before {
    content: "\10e036";
}

.icon-tools:before {
    content: "\10e037";
}

.icon-login:before {
    content: "\10e038";
}

.icon-search:before {
    content: "\10e039";
}

.icon-calendar:before {
    content: "\10e03a";
}

.icon-help:before {
    content: "\10e03b";
}

.icon-general:before,
.icon-info:before,
.icon-success:before {
    content: "\10e03c";
}

.icon-error:before {
    content: "\10e03d";
}

.icon-warning:before,
.icon-alert:before {
    content: "\10e03e";
}

.icon-newpage:before {
    content: "\10e03f";
}

.icon-arrow-up:before {
    content: "\10e040";
}

.icon-arrow-down:before {
    content: "\10e041";
}

.icon-arrow-left:before {
    content: "\10e042";
}

.icon-arrow-right:before {
    content: "\10e043";
}

.icon-circle-up:before {
    content: "\10e044";
}

.icon-circle-down-b:before {
    content: "\10e045";
}

.icon-circle-down:before {
    content: "\10e046";
}

.icon-circle-left:before {
    content: "\10e047";
}

.icon-circle-right:before {
    content: "\10e048";
}

.icon-check:before {
    content: "\10e049";
}

.icon-cancel:before {
    content: "\10e04a";
}

.icon-circle-check:before {
    content: "\10e04b";
}

.icon-circle-cancel:before,
.icon-close:before {
    content: "\10e04c";
}

.icon-more-13px:before {
    content: "\10e04d";
}

.icon-collapse:before {
    content: "\10e04e";
}

.icon-expand:before {
    content: "\10e04f";
}

.icon-explosives:before {
    content: "\10e050";
}

.icon-bullets:before {
    content: "\10e051";
}

.icon-fireworks:before {
    content: "\10e052";
}

.icon-gascylinder45:before {
    content: "\10e053";
}

.icon-oxygentank:before {
    content: "\10e054";
}

.icon-aerosol:before {
    content: "\10e055";
}

.icon-gascylinder:before {
    content: "\10e056";
}

.icon-radioactive:before {
    content: "\10e057";
}

.icon-flammable:before {
    content: "\10e058";
}

.icon-gascan:before {
    content: "\10e059";
}

.icon-match:before {
    content: "\10e05a";
}

.icon-nailpolishremover:before {
    content: "\10e05b";
}

.icon-chainsawsmooth:before {
    content: "\10e05c";
}

.icon-chainsaw:before {
    content: "\10e05d";
}

.icon-lighterandfluid:before {
    content: "\10e05e";
}

.icon-corrosive:before {
    content: "\10e05f";
}

.icon-ecigar:before {
    content: "\10e076";
}

.icon-oxidizer:before {
    content: "\10e060";
}

.icon-bleach:before {
    content: "\10e061";
}

.icon-hydrogenperoxide:before {
    content: "\10e062";
}

.icon-ajaxcomet:before {
    content: "\10e063";
}

.icon-skullandbones:before {
    content: "\10e064";
}

.icon-skullandbonesbottle:before {
    content: "\10e065";
}

.icon-skullandbonesbox:before {
    content: "\10e066";
}

.icon-carbattery:before {
    content: "\10e067";
}

.icon-lightningbolt:before {
    content: "\10e068";
}

.icon-lithiumion:before {
    content: "\10e069";
}

.icon-drill:before {
    content: "\10e06a";
}

.icon-edit:before {
    content: "\10e06b";
}

.icon-list:before {
    content: "\10e06c";
}

.icon-menu:before {
    content: "\10e06d";
}

.icon-power-port:before {
    content: "\10e06e";
}

.icon-clothes-hanger:before {
    content: "\10e06f";
}

.icon-delete:before {
    content: "\10e070";
}
.icon-add:before {
    content: "\10e071";
}
.icon-preferences:before {
    content: "\10e072";
}
.icon-like:before {
    content: "\10e073";
}
.icon-doc:before {
    content: "\10e074";
}
.icon-pets:before{
    content: "\10e075";
}
.icon-ecigar:before{
    content: "\10e076";
}
.icon-departing:before {
    content: "\10e083";
}

.icon-landing:before {
    content: "\10e084";
}

.icon-stops:before {
    content: "\10e085";
}

.icon-airport:before {
    content: "\10e086";
}

.icon-image:before {
    content: "\10e087";
}

.icon-trash:before {
    content: "\10e088";
}

.icon-locate:before {
    content: "\10e089";
}

.icon-handbag:before {
    content: "\10e900";
}

.icon-briefcase:before {
    content: "\10e901";
}

.icon-bookbag:before {
    content: "\10e902";
}

.icon-carryon:before{
    content: "\10e903"
}

.icon-ban:before{
    content: "\10e904"
}

.icon-square:before{
    content: "\25A0";
    color: $lightGray;
}
.icon-required:before {
    color:$darkRed;
    @include font-size($baseFontSize - 3);
    font-weight:bold;
    font-family:inherit;
    content:"\25CF";
    line-height:0;
    position:relative;
    bottom:3px;
}

.aa-language-select-indicator {
    height: 24px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 4px;
    padding: 0;
    width: 24px;
    overflow: hidden;
}

.aa-language-select-indicator img{
    max-width: none;
    margin-left: -150px; margin-top: 0px;
}
.aa-cntry-US .aa-language-select-indicator img {
    margin-left: -150px; margin-top: 0px;
}

.aa-cntry-AR .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -120px;
}

.aa-cntry-AU .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -60px;
}

.aa-cntry-BZ .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -180px;
}

.aa-cntry-BE .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -180px;
}

.aa-cntry-BO .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -180px;
}

.aa-cntry-BR .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -180px;
}

.aa-cntry-CA .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -180px;
}

.aa-cntry-CL .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -150px;
}

.aa-cntry-CN .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -150px;
}

.aa-cntry-CO .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -150px;
}

.aa-cntry-CR .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -150px;
}

.aa-cntry-CZ .aa-language-select-indicator img {
    margin-left: -120px;
    margin-top: -240px;
}

.aa-cntry-DO .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -150px;
}

.aa-cntry-EC .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -120px;
}

.aa-cntry-SV .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -120px;
}

.aa-cntry-FR .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -120px;
}

.aa-cntry-DE .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -120px;
}

.aa-cntry-GT .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -120px;
}


.aa-cntry-HN .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -90px;
}

.aa-cntry-IN .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -90px;
}

.aa-cntry-IE .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -90px;
}

.aa-cntry-IT .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -90px;
}

.aa-cntry-JP .aa-language-select-indicator img {
    margin-left: 0px; margin-top: 0px;
}

.aa-cntry-KR .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -30px;
}

.aa-cntry-MX .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -90px;
}

.aa-cntry-NL .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -90px;
}

.aa-cntry-NI .aa-language-select-indicator img {
    margin-left: -180px; margin-top: -60px;
}

.aa-cntry-PA .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -60px;
}

.aa-cntry-PE .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -60px;
}

.aa-cntry-PR .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -60px;
}

.aa-cntry-RU .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -60px;
}

.aa-cntry-ES .aa-language-select-indicator img {
    margin-left: -150px; margin-top: -30px;
}

.aa-cntry-CH .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -30px;
}

.aa-cntry-GB .aa-language-select-indicator img {
    margin-left: -180px; margin-top: 0px;
}

.aa-cntry-UY .aa-language-select-indicator img {
    margin-left: -120px; margin-top: 0px;
}

.aa-cntry-VE .aa-language-select-indicator img {
    margin-left: -90px; margin-top: 0px;
}

.aa-cntry-TT .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -30px;
}

.aa-cntry-BM .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -180px;
}

.aa-cntry-AW .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -90px;
}

.aa-cntry-CW .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -150px;
}

.aa-cntry-KY .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -180px;
}

.aa-cntry-BB .aa-language-select-indicator img {
    margin-left: -210px; margin-top: 0px;
}

.aa-cntry-JM .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -90px;
}

.aa-cntry-AG .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -150px;
}

.aa-cntry-TC .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -30px;
}

.aa-cntry-HT .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -120px;
}

.aa-cntry-AI .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -180px;
}

.aa-cntry-BS .aa-language-select-indicator img {
    margin-left: -210px; margin-top: -30px;
}

.aa-cntry-BQ .aa-language-select-indicator img {
    margin-left: -30px; margin-top: 0px;
}

.aa-cntry-LC .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -60px;
}

.aa-cntry-GD .aa-language-select-indicator img {
    margin-left: -60px; margin-top: -120px;
}

.aa-cntry-KN .aa-language-select-indicator img {
    margin-left: -120px; margin-top: -30px;
}

.aa-cntry-VC .aa-language-select-indicator img {
    margin-left: -90px; margin-top: -30px;
}

.aa-cntry-SX .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -60px;
}

.aa-cntry-VG .aa-language-select-indicator img {
    margin-left: -60px; margin-top: 0px;
}

.aa-cntry-DM .aa-language-select-indicator img {
    margin-left: -30px; margin-top: -150px;
}

.aa-cntry-PY .aa-language-select-indicator img {
    margin-left: 0px; margin-top: -210px;
}

.aa-cntry-DK .aa-language-select-indicator img {    // Denmark
    margin-left: -30px; margin-top: -210px;
}

.aa-cntry-NO .aa-language-select-indicator img {    // Norway
    margin-left: -60px; margin-top: -210px;
}

.aa-cntry-SE .aa-language-select-indicator img {    // Sweden
    margin-left: -90px; margin-top: -210px;
}

.aa-cntry-IL .aa-language-select-indicator img {    // Israel
    margin-left: -120px; margin-top: -210px;
}

.aa-cntry-HK .aa-language-select-indicator img {    // Hong Kong
    margin-left: -150px; margin-top: -210px;
}

.aa-cntry-NZ .aa-language-select-indicator img {    // New Zealand
    margin-left: -180px; margin-top: -210px;
}

.aa-cntry-FI .aa-language-select-indicator img {    // Finland
    margin-left: -210px; margin-top: -210px;
}

.aa-cntry-HU .aa-language-select-indicator img {    // Hungary
    margin-left: 0; margin-top: -240px;
}

.aa-cntry-PT .aa-language-select-indicator img {    // Portugal
    margin-left: -30px; margin-top: -240px;
}

.aa-cntry-GR .aa-language-select-indicator img {    // Greece
    margin-left: -60px; margin-top: -240px;
}

.aa-cntry-IS .aa-language-select-indicator img {    // Iceland
    margin-left: -90px; margin-top: -240px;
}

.aa-cntry-HR .aa-language-select-indicator img {  //Croatia
    margin-left: -150px;
    margin-top: -240px;
}
.icon-navigationarrow {
    content: '\203A';
    display: inline-block;
    font-family: $sansFontFamilyRegular;
    @include font-size(27);
}

.icon-downarrowhead:before{
    @extend .icon-navigationarrow;
    @include rotate(90);
}
.icon-uparrowhead:before{
    @extend .icon-navigationarrow;
    @include rotate(270);
}

.aa-cntry-SG .aa-language-select-indicator img {  // Singapore
    margin-left: -180px;
    margin-top: -240px;
}

.aa-cntry-PL .aa-language-select-indicator img {  // Poland
    margin-left: -210px;
    margin-top: -240px;
}
