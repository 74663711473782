@import "./variables";
.global-message {
    font-family: $sansFontFamilyRegular;
    padding: 0 50px;

    .container {
        padding: 0 0 10px;
    }

    .global-message-title {
        position: relative;
        font-family: $sansFontFamilyRegular;
        color: $darkGray;
        display: block;
        margin: .83em 0 0;

        @include font-size-line-height(24);

        &:before {
            display: block;
            position: absolute;
            left: -40px;
            top: 4px;
            height: 24px;
            width: 24px;
        }

        .icon-close {
            cursor: pointer;
            float: right;
            border: 0;
            background: none;

            &:before {
                color: $detroitBlue;
                font-size: 24px !important;
                vertical-align: baseline;
            }

            &:focus {
                outline: 2px solid $brandBlue;
            }
        }
    }

    .global-message-content {
        color: $darkGray;
        margin: 10px 0 0 0;

        @include font-size-line-height(16);

        .global-message-link {
            display: block;
            color: $detroitBlue;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 10px;

            &:hover {
                text-decoration: underline;
            }
        }

        .global-message-text {
            color: $detroitBlue;
            font-family: $sansFontFamilyMedium;
        }

        .global-message-button {
            @include aileron-button;
            display: inline-block;
            margin-bottom: 12px;
            min-height: 34px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.global-message--info {
        border-top: 5px solid $brandBlue;
        background-color: #f5faff;

        .global-message-title:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%230061AB' fill-rule='nonzero' d='M12 22.667c-5.867 0-10.667-4.8-10.667-10.667S6.133 1.333 12 1.333 22.667 6.133 22.667 12 17.867 22.667 12 22.667z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M13.667 9.667v9.666h-3.334V9.667h3.334zm0-4.334v3h-3.334v-3h3.334z'/%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }
}

@include respond-to(phone) {
    .global-message {
        padding: 0;

        .global-message-title {
            margin: 15px 30px 0 40px;
            font-family: $sansFontFamilyRegular;
            @include font-size-line-height(15);

            &:before {
                display: block;
                left: -25px;
                top: 1px;
                width: 15px;
                height: 15px;
            }
        }

        .global-message-content {
            padding: 10.5px 30px 10px 40px;
            margin: 0;
        }
    }
}
