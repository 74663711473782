[class*="shadow"][class*="-up"],
%shadow-up {
    @include shadow-up;
}

[class*="shadow"][class*="-down"] {
    @include shadow-down;
}

hr[class*="shadow"] {
    border: none;
    height: 4px;
}

[class*="shadow"][class*="-both"] {
    @include shadow-both;
}

[class*="shadow-container"] {
    @include shadow-both;
    margin-top: $baseLineHeight * 1.5;
    margin-bottom: $baseLineHeight * 1.5;
    padding-top: $baseLineHeight * 1.5;
    padding-bottom: $baseLineHeight * 1.5;
}

.bookends {
    @include background(
        url(#{$americanImgPath}chrome/rebrand/shadow12-up.png),
        url(#{$americanImgPath}chrome/rebrand/shadow12-down.png),
        linear-gradient($ultraLightGray, #FFF 20%, #FFF 80%, $ultraLightGray)
    );
    background-position: 50% bottom, 50% top, left top;
    background-repeat: no-repeat;
    background-size: 100% 4px, 100% 4px, auto;
    padding-top: $baseLineHeight;
    padding-bottom: $baseLineHeight;
    padding-left: 11px;
    padding-right: 11px;

    .lt-ie9 & {
        border-bottom: 1px solid $lightGray;
        border-top: 1px solid $lightGray;
    }
}

.bookends-sidebar {
    @extend .bookends;
    background: url(#{$americanImgPath}chrome/rebrand/shadow12-up.png), url(#{$americanImgPath}chrome/rebrand/shadow12-down.png), $ultraLightGray;
    background-position: 50% bottom, 50% top, left top;
    background-repeat: no-repeat;
    background-size: 100% 4px, 100% 4px, auto;
}
